import $ from 'jquery'
$('.accordion__item-title, .accordion__item .hidden_content .btn').on(
  'click',
  function() {
    var parent = $(this).closest('.accordion__item')
    if (parent.hasClass('active')) {
      $('.accordion__item').removeClass('active')
      $('.accordion__item .hidden_content').slideUp()
    } else {
      $('.accordion__item').removeClass('active')
      $('.accordion__item .hidden_content').slideUp()
      parent.addClass('active')
      parent.find('.hidden_content').slideDown()
    }
  }
)
