import cssVars from 'css-vars-ponyfill'
import inseratErstellenForm from '../lib/inseratErstellenForm'

const dhsvCommon = {
  stickyBanner() {
    let scrollTop
    const body = $('body')
    const distance = $('.banner__top').outerHeight(true)

    function bodyMargin() {
      const headerHeight = $('.banner').outerHeight(true)
      body.css('margin-top', headerHeight)
    }

    function handleScroll() {
      scrollTop = $(window).scrollTop()

      if (scrollTop <= distance) {
        body.removeClass('sticky-nav')
      } else {
        body.addClass('sticky-nav')
      }
    }

    bodyMargin()
    handleScroll()
    $(window).on('scroll', this.throttled(10, handleScroll))
    $(window).on('resize', this.throttled(10, bodyMargin))
  },
  throttled(delay, fn) {
    let lastCall = 0
    return function(...args) {
      const now = new Date().getTime()
      if (now - lastCall < delay) {
        return
      }
      lastCall = now
      return fn(...args)
    }
  },
  setBodyWidth() {
    cssVars({
      variables: { '--body-width': document.body.clientWidth + 'px' },
    })
  },
  smoothScroll(link, e) {
    let destination = 0

    if (e) e.preventDefault()
    var hash = false
    if (!link && window.location.hash) {
      hash = window.location.hash
    } else if (link && link.indexOf('#') == 0) {
      hash = link
    } else if (
      link &&
      location.pathname.replace(/^\//, '') ==
        link.pathname.replace(/^\//, '') &&
      location.hostname == link.hostname
    ) {
      hash = link.hash
    }

    if (hash && $(hash).length) {
      destination = $(hash).offset().top - 75
      history.replaceState(null, null, hash)
    }

    $('html, body').animate(
      {
        scrollTop: destination,
      },
      600
    )

    $(window).trigger('hashchange')
  },
  primaryMenuInit() {
    $('body')
      .on('click', '.js-nav-primary-toggler', function() {
        $(this).toggleClass('active')
        $('.js-nav-primary')
          .slideToggle(300)
          .toggleClass('is-open')
      })
      .on('click', '.js-submenu-toggle', function() {
        $(this)
          .next('.sub-menu')
          .slideToggle(300)
      })
  },
  hideVideoOverlay() {
    $('.js-video-overlay').click(function() {
      const iframeSrc = $(this)
        .find('iframe')
        .attr('src')
      const url = ';autoplay=1'
      $(this)
        .find('.wpb_video_wrapper__overlay')
        .hide()
      $(this)
        .find('iframe')
        .attr('src', iframeSrc + url)
    })
  },
  conactBoxHandler() {
    const contactBox = $('.js-contact-box')
    contactBox.click(function() {
      $(this).addClass('box-modal')
    })
    $('.js-box-close').click(function(e) {
      e.stopPropagation()
      $('.js-contact-box').removeClass('box-modal')
    })
  },
  conactBoxShow() {
    const contactBox = $('.js-contact-box')
    let winH = $(window).height()
    $(window)
      .on('scroll', function() {
        contactBox.toggleClass('show', $(this).scrollTop() > winH - 400)
      })
      .on('resize', function() {
        winH = $(this).height()
      })
  },
  tableWrap() {
    $('.vc_section table').wrap('<div class="table_wrapper"></div>')
  },
  gfieldButtonsRemove() {
    $('.gform_wrapper ').each(function() {
      if ($(this).find('.gfield .btn').length > 0) {
        $(this)
          .find('.gform_footer')
          .css({
            position: 'absolute',
            left: '-9999px',
          })
      }
    })
  },
  gfieldTextfieldsDecor() {
    $('.gform_wrapper input[type=text], .gform_wrapper textarea').each(
      function() {
        $(this).keyup(function() {
          if (!$(this).val()) {
            $(this)
              .parent()
              .removeClass('active')
          } else {
            $(this)
              .parent()
              .addClass('active')
          }
        })
      }
    )
  },
  searchToggle() {
    $('.js-search-button').on('click', function() {
      if ($(this).hasClass('active')) {
        $(this).removeClass('active')
      } else {
        $(this).addClass('active')
      }
      $('.searchform-wrapper').toggleClass('opened')
    })
  },
  vcTabsSecondRow() {
    $('.vc_tta-tabs').each(function() {
      let elementsCount = $(this).find('.vc_tta-tab').length
      if (elementsCount > 4) {
        $(this)
          .find('.vc_tta-tab')
          .each(function(e) {
            if (e > 3) {
              $(this).addClass('second_row')
            }
            if ((e + 1) % 4 == 0) {
              $(this).addClass('nbr')
            }
          })
      }
    })
  },
  mobileVcTabs() {
    $('.vc_tta-panels').each(function() {
      $(this).prepend(
        '<div class="mobile_tabs_list">\n' +
          '        <span></span>\n' +
          '        <ul></ul>\n' +
          '    </div>'
      )
    })
    $('.vc_tta-panel-heading').each(function() {
      let title = $(this).text()
      let href = $(this)
        .find('a')
        .attr('href')
      let mobileList = $(this)
        .closest('.vc_tta')
        .find('.mobile_tabs_list')
      mobileList
        .find('ul')
        .append('<li data-value="' + href + '">' + title + '</li>')
    })

    $('.mobile_tabs_list').each(function() {
      $(this)
        .find('span')
        .text(
          $(this)
            .find('li:first-of-type')
            .text()
        )
      $(this)
        .find('li:first-of-type')
        .addClass('hidden')
    })
    $('.mobile_tabs_list').on('click', function() {
      $(this)
        .find('ul')
        .slideToggle()
        .closest('.mobile_tabs_list')
        .toggleClass('opened')
    })

    $('.mobile_tabs_list').on('click', 'li', function() {
      let target = $(this).data('value')
      let title = $(this).text()
      $(this)
        .closest('.mobile_tabs_list')
        .find('span')
        .text(title)
      $(this)
        .siblings('li')
        .removeClass('hidden')
        .end()
        .addClass('hidden')

      $('.vc_tta-panel-title')
        .find('a[href*="' + target + '"]')
        .trigger('click')
    })
  },

  init() {
    this.stickyBanner()
    this.primaryMenuInit()
    this.hideVideoOverlay()
    this.conactBoxHandler()
    this.conactBoxShow()
    this.tableWrap()
    this.gfieldButtonsRemove()
    this.gfieldTextfieldsDecor()
    this.searchToggle()
    this.setBodyWidth()
    this.vcTabsSecondRow()
    this.mobileVcTabs()
    if ($('.gform_wrapper').length) {
      inseratErstellenForm()
    }

    if (window.location.hash) {
      this.smoothScroll(window.location.hash)
    }

    $('body').on(
      'click',
      'a[href^="#"]:not([href="#"],[href="#0"],[href^="#fancy-"],[data-toggle],[role="tab"],[data-vc-accordion],[data-vc-tabs])',
      e => {
        this.smoothScroll($(e.currentTarget).attr('href'), e)
      }
    )
  },
  onResize() {
    $(window).on('resize', () => {
      this.setBodyWidth()
    })
  },
}

export default {
  init() {
    // JavaScript to be fired on all pages
    dhsvCommon.init()
    dhsvCommon.onResize()
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
