let infoPosition = function() {
  $(document)
    .find('.services-item')
    .each(function() {
      let $this = $(this)
      let titleHeight = $this
        .find('.services-item__title')
        .outerHeight(true, true)
      $this
        .find('.service_description')
        .css('top', 'calc(100% - 30px - ' + titleHeight + 'px)')
      $this.find('.services-item__info').css('padding-bottom', titleHeight)
      $this
        .find('.hidden_description')
        .css('height', 'calc(100% - ' + titleHeight + 'px)')
    })
}

$(window).on('load', () => {
  infoPosition()
})

$(window).on('resize', () => {
  infoPosition()
})
