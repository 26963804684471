import curry from 'ramda/es/curry'
import compose from 'ramda/es/compose'
import fromPairs from 'ramda/es/fromPairs'
import map from 'ramda/es/map'
import pair from 'ramda/es/pair'
import keys from 'ramda/es/keys'
import length from 'ramda/es/length'
import chain from 'ramda/es/chain'
import uniq from 'ramda/es/uniq'
import countBy from 'ramda/es/countBy'
import identity from 'ramda/es/identity'
import filter from 'ramda/es/filter'
import join from 'ramda/es/join'

/*
convert

 [{key: 1},{key: 2},{key: 3},{key: 4},{key: 5}]

to

  {
    "1": {key: 1},
    "2": {key: 2},
    "3": {key: 3},
    "4": {key: 4},
  }
*/
// normalizeByKey :: string -> array -> object
export const normalizeByKey = curry((key, arr) =>
  compose(fromPairs, map(e => pair(e[key], e)))(arr)
)

// determine if two or more objects share at least one key
// [object] -> bool
export const objectsShareKey = objs => {
  const objKeys = chain(keys, objs)
  return length(objKeys) > length(uniq(objKeys))
}

// get all keys that appear at least twice in an array of objects
// [object] -> [string]
export const getDuplicateKeys = objs =>
  compose(keys, filter(c => c > 1), countBy(identity), chain(keys))(objs)

// get all keys that appear at least twice in an array of objects as a comma-separated string
// [object] -> string
export const getDuplicateKeysString = compose(join(', '), getDuplicateKeys)
