import $ from 'jquery'
import 'slick-carousel'

$(document).ready(function() {
  dhsv_vc_teaser_slider()
})
const teaserSliders = $('.js-teaser-slider')

function dhsv_vc_teaser_slider() {
  teaserSliders.each(function() {
    const slider = $(this)
    slider.slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      arrows: true,
      dots: true,
      infinite: false,
      appendDots: $('.slide-m-dots', slider.closest('.dhsv_vc_teaser_slider')),
      prevArrow: $('.slide-m-prev', slider.closest('.dhsv_vc_teaser_slider')),
      nextArrow: $('.slide-m-next', slider.closest('.dhsv_vc_teaser_slider')),
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    })
  })
}
