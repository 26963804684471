function vc_toggle_content() {
  $('.vc_toggle_content').each(function() {
    var togglerHeight = $(this)
      .find('.toggler')
      .outerHeight()
    if (togglerHeight < 350) {
      $(this)
        .find('.toggler_button')
        .css('display', 'none')
      $(this).addClass('disable')
    } else {
      $(this)
        .find('.toggler_button')
        .css('display', 'block')
      $(this).removeClass('disable')
    }
  })
}

$(window).on('load', function() {
  vc_toggle_content()
})

$('.vc_toggle_content').on('click', '.toggler_button', function() {
  var $wrapper = $(this).closest('.vc_toggle_content')
  var togglerHeight = $wrapper.find('.toggler').outerHeight()
  $wrapper.toggleClass('disable')
  if ($wrapper.hasClass('disable')) {
    $wrapper.css('max-height', togglerHeight)
  } else {
    $wrapper.css('max-height', '')
  }
})
