/* global gform tinyMCE */
import jobAdTemplates from './jobAdTemplates'

let lastTemplate = null

// this implements a mechanism for loading templates into the rich text field of the 'Inserat erstellen' gravity form
export default () => {
  gform.addAction('gform_post_conditional_logic_field_action', function(
    formId,
    action,
    targetId
  ) {
    //form #3 is 'Inserat erstellen' form, field #24 is the rich text field for the body text
    if (targetId === '#field_3_24') {
      const jobAdTemplatesList = document.querySelector(
        '#gform_3 .job-ad-templates'
      )
      if (action === 'hide' && jobAdTemplatesList) {
        // to be compatible with ie11, call parentNode.removeChild() instead of remove()
        jobAdTemplatesList.parentNode.removeChild(jobAdTemplatesList)
      }
      if (action === 'show' && !jobAdTemplatesList) {
        const jobAdBodyField = document.querySelector('#gform_3 #field_3_24')
        const list = document.createElement('div')
        list.onclick = e => {
          e.preventDefault()
          const { target: { className, tagName } } = e
          if (tagName === 'A' && className) {
            const template = jobAdTemplates[className]
            if (tinyMCE && template) {
              const editor = tinyMCE.get('input_3_24')
              const editorContent = editor.getContent()
              if (
                !editorContent ||
                lastTemplate === editorContent ||
                window.confirm(
                  'Wollen Sie wirklich eine neue Vorlage laden? Ihr vorhandener Text wird ersetzt.'
                )
              ) {
                editor.setContent(template)
                lastTemplate = editor.getContent()
              }
            }
          }
        }
        list.className = 'job-ad-templates'
        list.innerHTML = `
<span class="h5">Vorlage laden:</span>
<ul>
  <li><a href="#" class="sani">Sanitätshausfachverkäufer/in</a></li>
  <li><a href="#" class="ortho">Orthopädietechniker/in</a></li>
  <li><a href="#" class="reha">Rehatechniker/in</a></li>
  <li><a href="#" class="stoma">Stomatherapeut/in</a></li>
</ul>`
        jobAdBodyField.insertAdjacentElement('afterend', list)
      }
    }
  })
}
